import React, { Fragment } from 'react';
import * as constants from '../../constants';
import * as utils from '../../utils/Utils';
import AsyncSelect from 'react-select/lib/Async';
import {
  InputField,
  SelectField,
  MaskInput,
  SelectorAsync,
  Field,
} from '../Form/Form';
import { getUpdateCidades } from './ClienteActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import BuscaEnderecoPorCEP from '../BuscaEnderecoCEP';
import BuscarCidade from './BuscarCidade';

const mapStateToProps = state => ({
  opcoes: state.opcoes,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUpdateCidades,
    },
    dispatch
  );

const ClienteFields = ({ values, opcoes, onChange: propsOnChange }) => {
  const onChange = e => propsOnChange(e.target.name, e.target.value);

  const requiredFields = constants.REQUIREDS_FIELDS[values.tipo]
    ? constants.REQUIREDS_FIELDS[values.tipo]
    : constants.REQUIREDS_FIELDS.CONJUGE;
  const shouldRequired = field => requiredFields.includes(field);

  const preparaEndereco = endereco => {
    propsOnChange('estado', endereco.uf);
    propsOnChange('bairro', endereco.bairro);
    propsOnChange('endereco', endereco.logradouro);
    propsOnChange('codigo_cidade', endereco.codigo_cidade);
    propsOnChange('descricao_cidade', utils.capitalize(endereco.cidade));
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-12 col-lg-6 col-xl-4">
          <h5 className="text-muted">Contato</h5>
          <hr />
          <InputField
            name="nome"
            label="Nome"
            required={shouldRequired('nome')}
            maxLength={40}
            invalid={values.errors && values.errors.nome}
            defaultValue={values.nome}
            onChange={onChange}
          />
          <div className="row">
            <div className="col-12 col-md-8 col-xl-12">
              <InputField
                name="email"
                label="E-mail"
                type="email"
                placeholder="Seu email"
                required={shouldRequired('email')}
                maxLength={60}
                invalid={values.errors && values.errors.email}
                defaultValue={values.email}
                onChange={onChange}
              />
            </div>
            <div className="col-12 col-md-4 col-xl-12">
              <SelectField
                name="sexo"
                label="Sexo"
                required={shouldRequired('sexo')}
                invalid={values.errors && values.errors.sexo}
                defaultValue={values.sexo}
                onChange={onChange}
                placeholder={true}
                options={[
                  {
                    label: 'Masculino',
                    value: 'M',
                  },
                  {
                    label: 'Feminino',
                    value: 'F',
                  },
                ]}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <MaskInput
                name="tel_celular"
                label="Celular"
                type="tel"
                placeholder="(00) 00000-0000"
                mask={utils.formatPhoneNumber}
                invalid={values.errors && values.errors.tel_celular}
                defaultValue={values.tel_celular}
                onChange={onChange}
              />
            </div>
            <div className="col-12 col-md-6">
              <MaskInput
                name="tel_residencial"
                label="Telefone Residencial"
                type="tel"
                placeholder="(00) 00000-0000"
                mask={utils.formatPhoneNumber}
                invalid={values.errors && values.errors.tel_residencial}
                defaultValue={values.tel_residencial}
                onChange={onChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6">
              <MaskInput
                name="tel_comercial"
                label="Telefone Comercial"
                type="tel"
                placeholder="(00) 00000-0000"
                mask={utils.formatPhoneNumber}
                invalid={values.errors && values.errors.tel_comercial}
                defaultValue={values.tel_comercial}
                onChange={onChange}
              />
            </div>
            <div className="col-12 col-md-6">
              <MaskInput
                name="nascimento"
                label="Data de Nascimento"
                type="tel"
                required={shouldRequired('nascimento')}
                placeholder="Ex.: 29/12/1990"
                mask={constants.MASK_DATE}
                defaultValue={values.nascimento}
                invalid={values.errors && values.errors.nascimento}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        <BuscaEnderecoPorCEP
          onChangeCEP={onChange}
          quandoCarregarEndereco={endereco => preparaEndereco(endereco)}
        >
          {({ buscandoEndereco, erroBuscaEndereco, onChangeCEP }) => (
            <div className="col-12 col-lg-6 col-xl-4">
              <h5 className="text-muted">Endereço</h5>
              <hr />
              <div className="row">
                <div className="col-12 col-md-6">
                  <MaskInput
                    name="cep"
                    label="CEP"
                    type="tel"
                    required={shouldRequired('cep')}
                    placeholder="00000-000"
                    mask={constants.MASK_CEP}
                    invalid={
                      (values.errors && values.errors.cep) ||
                      (erroBuscaEndereco && 'CEP não encontrado')
                    }
                    value={values.cep}
                    onChange={onChangeCEP}
                    disabled={buscandoEndereco}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <SelectField
                    name="estado"
                    label="Estado"
                    required={shouldRequired('estado')}
                    value={values.estado}
                    invalid={values.errors && values.errors.estado}
                    options={Object.keys(constants.ESTADOS).map(item => ({
                      value: item,
                      label: `${item} - ${constants.ESTADOS[item]}`,
                    }))}
                    onChange={e => {
                      onChange(e);
                      propsOnChange('codigo_cidade', null);
                      propsOnChange('descricao_cidade', null);
                    }}
                  />
                </div>
              </div>

              <BuscarCidade estado={values.estado}>
                {({ carregarCidades }) => (
                  <Field
                    label="Cidade"
                    name="codigo_cidade"
                    invalid={values.errors && values.errors.codigo_cidade}
                    required={shouldRequired('codigo_cidade')}
                  >
                    <AsyncSelect
                      id="codigo_cidade"
                      className="AsyncSelect-container"
                      classNamePrefix="AsyncSelect"
                      invalid={values.errors && values.errors.codigo_cidade}
                      isDisabled={buscandoEndereco}
                      loadingMessage={() => 'Carregando...'}
                      noOptionsMessage={() =>
                        'Digite uma cidade. Ex.: Brasília'
                      }
                      placeholder="Pesquisar cidade..."
                      loadOptions={carregarCidades}
                      defaultOptions={[]}
                      onChange={option => {
                        propsOnChange('codigo_cidade', option.value);
                        propsOnChange('descricao_cidade', option.label);
                      }}
                      value={
                        values.codigo_cidade
                          ? {
                              value: parseInt(values.codigo_cidade),
                              label:
                                utils.capitalize(values.descricao_cidade) ||
                                values.codigo_cidade,
                            }
                          : { value: '', label: 'Selecione uma cidade' }
                      }
                    />
                  </Field>
                )}
              </BuscarCidade>

              <InputField
                name="bairro"
                label="Bairro"
                placeholder="Ex.: Jardim Paulista"
                maxLength={20}
                required={shouldRequired('bairro')}
                invalid={values.errors && values.errors.bairro}
                value={values.bairro}
                onChange={onChange}
                disabled={buscandoEndereco}
              />
              <InputField
                name="endereco"
                label="Endereço"
                required={shouldRequired('endereco')}
                maxLength={40}
                placeholder="Avenida, Rua, Logradouro..."
                invalid={values.errors && values.errors.endereco}
                value={values.endereco}
                onChange={onChange}
                disabled={buscandoEndereco}
              />
              <div className="row">
                <div className="col-12 col-md-6">
                  <InputField
                    name="numero"
                    label="Número"
                    type="tel"
                    placeholder="Ex.: 123"
                    required={shouldRequired('numero')}
                    maxLength={10}
                    invalid={values.errors && values.errors.numero}
                    defaultValue={values.numero}
                    onChange={onChange}
                    disabled={buscandoEndereco}
                  />
                </div>
                <div className="col-12 col-md-6">
                  <InputField
                    name="complemento"
                    label="Complemento"
                    required={shouldRequired('complemento')}
                    placeholder="Ex.: Fundos"
                    maxLength={30}
                    invalid={values.errors && values.errors.complemento}
                    defaultValue={values.complemento}
                    onChange={onChange}
                    disabled={buscandoEndereco}
                  />
                </div>
              </div>
            </div>
          )}
        </BuscaEnderecoPorCEP>
        <div className="col-12 col-lg-6 col-xl-4">
          <h5 className="text-muted">Documentos</h5>
          <hr />
          <MaskInput
            name="cpf"
            label="CPF/CNPJ"
            readOnly={true}
            mask={utils.formatCPF}
            invalid={values.errors && values.errors.cpf}
            defaultValue={values.cpf}
            onChange={onChange}
          />
          {(opcoes.isLoadingProfissoes || opcoes.isErrorLoadingProfissoes) && (
            <div className="form-group">
              <label htmlFor="profissao">Profissão</label>
              <input
                type="text"
                readOnly
                className="form-control-plaintext"
                id="profissao"
                value={
                  opcoes.isLoadingProfissoes
                    ? 'Carregando...'
                    : 'Erro ao buscar as profissões'
                }
              />
            </div>
          )}
          {opcoes.profissoes &&
            !opcoes.isLoadingProfissoes &&
            !opcoes.isErrorLoadingProfissoes && (
              <SelectorAsync
                name="profissao"
                label="Profissão"
                required={shouldRequired('profissao')}
                placeholder="Selecione uma profissão"
                invalid={values.errors && values.errors.profissao}
                defaultValue={{
                  value: opcoes.profissoes.byId[values.profissao]
                    ? opcoes.profissoes.byId[values.profissao].codigo
                    : '',
                  label: opcoes.profissoes.byId[values.profissao]
                    ? opcoes.profissoes.byId[values.profissao].descricao
                    : '',
                }}
                loadOptions={input =>
                  new Promise(resolve => {
                    setTimeout(() => {
                      let search = opcoes.profissoes.list
                        .filter(profissao =>
                          profissao.descricao
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        )
                        .map(item => ({
                          value: item.codigo,
                          label: item.descricao,
                        }));

                      resolve(search);
                    }, 500);
                  })
                }
                onChange={({ value }) => propsOnChange('profissao', value)}
              />
            )}

          <InputField
            name="nacionalidade"
            label="Nacionalidade"
            placeholder="Ex.: brasileiro"
            required={shouldRequired('nacionalidade')}
            maxLength={30}
            defaultValue={values.nacionalidade}
            onChange={onChange}
          />
          <SelectField
            name="estado_civil"
            label="Estado Civil"
            required={shouldRequired('estado_civil')}
            defaultValue={values.estado_civil}
            options={constants.ESTADO_CIVIL_CLIENTE}
            onChange={onChange}
          />
          <div className="row">
            <div className="col-12 col-md-4 col-xl-6">
              <InputField
                name="rg"
                label="RG"
                placeholder="000000000"
                required={shouldRequired('rg')}
                maxLength={20}
                invalid={values.errors && values.errors.rg}
                defaultValue={values.rg}
                onChange={onChange}
              />
            </div>
            <div className="col-12 col-md-4 col-xl-6">
              <InputField
                name="emissor_rg"
                label="Emissor RG"
                required={shouldRequired('emissor_rg')}
                maxLength={20}
                placeholder="Emissor do RG"
                invalid={values.errors && values.errors.emissor_rg}
                defaultValue={values.emissor_rg}
                onChange={onChange}
              />
            </div>
            <div className="col-12 col-md-4 col-xl-12">
              <MaskInput
                name="data_emissao_rg"
                label="Data Emissão RG"
                type="tel"
                required={shouldRequired('data_emissao_rg')}
                placeholder="Ex.: 01/01/1881"
                mask={constants.MASK_DATE}
                invalid={values.errors && values.errors.data_emissao_rg}
                defaultValue={values.data_emissao_rg}
                onChange={onChange}
              />
            </div>
            <div className="col-12 col-md-6 col-xl-12">
              <InputField
                name="cnh"
                label="CNH"
                type="tel"
                maxLength={20}
                placeholder="Número da sua cnh"
                defaultValue={values.cnh}
                invalid={values.errors && values.errors.cnh}
                onChange={onChange}
              />
            </div>
            <div className="col-12 col-md-6 col-xl-12">
              <SelectField
                options={constants.ORGAO_EMISSOR_CNH}
                name="emissor_cnh"
                label="Emissor CNH"
                invalid={values.errors && values.errors.emissor_cnh}
                defaultValue={values.emissor_cnh}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default connect(mapStateToProps, mapDispatchToProps)(ClienteFields);
