import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { doLogout } from '~/components/Login/LoginActions';
import { setStep } from '../Scene/SceneAction';
import { NOME_EMPREENDIMENTO } from '../../constants';
import _ from 'lodash';

const mapStateToProps = state => ({
  cliente: state.cliente,
  opcoes: state.opcoes,
  currentStep: state.scene.currentStep,
  completeSteps: state.scene.completeSteps,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doLogout,
      setStep,
    },
    dispatch
  );

const LogoutButton = ({ onClick }) => (
  <button className="btn btn-link" onClick={onClick}>
    Sair&nbsp;
    <i className="fa fa-chevron-right" />
  </button>
);

const Scene = ({
  cliente,
  opcoes,
  currentStep,
  completeSteps,
  doLogout,
  setStep,
  children,
}) => {
  const steps = ['Dados Pessoais', 'Sócios/Cônjuge', 'Documentos'];

  if (!_.isEmpty(opcoes.pesquisa.byGrupoPergunta)) {
    steps.push('Pesquisa');

    if (opcoes.pesquisa.byGrupoPergunta.SATISFACAO) steps.push('Satisfação');
  }

  const isComplete = step => completeSteps.indexOf(step) > -1;

  return (
    <div className={`Scene ${cliente.data.conta && 'Scene--logged'}`}>
      <header className="Scene__header">
        <nav className="Header navbar is-shadow js-main-menu navbar-expand-lg navbar-dark main-menu-wrapper">
          <div className="container text-center">
            <div className="Header__logo navbar-brand h1 mb-0">
              <img
                src="//www.setpar.com.br/app/themes/setpar/dist/setpar-empreendimentos-w.svg"
                className="d-inline-block align-middle"
                alt="Validação do cadastro"
              />
              <span className="d-none d-md-inline">
                &nbsp;&nbsp;|&nbsp;&nbsp;Validação do cadastro
              </span>
            </div>
            {!cliente.isLoading && cliente.data.conta && (
              <Fragment>
                <span className="Header__user text-left text-lg-right navbar-text">
                  <span className="Header__user__name h5 d-block mb-0 font-weight-normal">
                    Olá, {cliente.data.nome}
                  </span>
                  <span className="Header__user__help d-none d-md-block">
                    Confirme seus dados para o lançamento do{' '}
                    {NOME_EMPREENDIMENTO}
                  </span>
                </span>
                <div className="logout-position d-block d-lg-none">
                  <LogoutButton onClick={doLogout} />
                </div>
              </Fragment>
            )}
          </div>
        </nav>

        {!cliente.isLoading && cliente.data.conta && (
          <nav className="navbar navbar-light bg-white shadow CounterStep px-0">
            <div className="container">
              <ul className="navbar-nav CounterStep__nav">
                {steps.map((item, index) => (
                  <li
                    className={`CounterStep__item navbar-text ${isComplete(
                      index
                    ) && 'is-complete'} ${currentStep === index &&
                      'is-current'}`}
                    key={index}
                    onClick={() => {
                      if (isComplete(index) && !cliente.data.conferido) {
                        setStep(index);
                      }
                    }}
                  >
                    <span className="CounterStep__item__number">
                      {index + 1}
                    </span>
                    <span className="CounterStep__item__label">
                      {item}
                      {currentStep === index && <small>Você está aqui</small>}
                    </span>
                  </li>
                ))}
              </ul>
              <ul className="navbar-nav ml-auto d-none d-lg-block">
                <li>
                  <LogoutButton onClick={doLogout} />
                </li>
              </ul>
            </div>
          </nav>
        )}
      </header>

      <section className="pb-5">{children}</section>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Scene);
