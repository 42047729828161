import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Form, { InputField, MaskInput } from '../Form/Form';
import {
  doLogin,
  toggleRecoveryCredentials,
  doRecoveryCredentials,
} from './LoginActions';
import Validator, { isRequired } from '../Form/Validator';
import * as utils from '~/utils/Utils';

const mapStateToProps = state => ({
  login: state.login,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      doLogin,
      doRecoveryCredentials,
      toggleRecoveryCredentials,
    },
    dispatch
  );

const Login = ({ doLogin, toggleRecoveryCredentials, login }) => {
  const { isFetching, errorLogin } = login;

  return (
    <div className="container" id="Login">
      <div className="row">
        <div className="col-12 col-md-6 offset-md-3">
          <div className="card mt-3 border-0 bg-transparent">
            <div className="card-body">
              {/* <h5 className="card-title">
                Validação de cadastro para o{' '}
                <strong>{constants.NOME_EMPREENDIMENTO}</strong>
              </h5> */}

              <div>
                <img
                  src="//repositorio.setcorp.com.br/imagens/empreendimentos/setvalley_horizonte_sjc/logo-composta-horizontal.png"
                  alt=""
                  className="w-100 mb-5"
                />
              </div>
              <p className="card-text mb-3 text-muted">
                Antes de iniciarmos a validação, precisamos carregar os seus
                dados. Informe o seu <strong>CPF</strong> ou{' '}
                <strong>CNPJ</strong> e a <strong>senha</strong> que você
                recebeu para carregarmos os seu dados.
                <br />
                <br />
                Caso não tenha recebido a sua senha, fale com o seu corretor.
              </p>
              <Form
                onSubmit={({ username, password }) => {
                  if (username && password) {
                    username = utils.clearNumber(username);
                    doLogin({ username, password });
                  }
                }}
              >
                {({ values, setValue, isDirty, isSubmitted }) => (
                  <Validator
                    values={values}
                    validations={{
                      username: [isRequired],
                      password: [isRequired],
                    }}
                  >
                    {({ errors }) => (
                      <div>
                        <MaskInput
                          name="username"
                          label="Usuário"
                          type="tel"
                          autoFocus
                          placeholder="Insira o seu CPF ou CNPJ"
                          disabled={isFetching}
                          defaultValue={values.username}
                          mask={utils.formatCPF}
                          onChange={e => setValue('username', e.target.value)}
                          invalid={
                            (isDirty('username') || isSubmitted()) &&
                            errors &&
                            errors.username
                          }
                        />
                        <InputField
                          name="password"
                          label="Senha"
                          type="password"
                          placeholder="Insira sua senha"
                          disabled={isFetching}
                          defaultValue={values.password}
                          onChange={e => setValue('password', e.target.value)}
                          invalid={
                            (isDirty('password') || isSubmitted()) &&
                            errors &&
                            errors.password
                          }
                        />
                        {isSubmitted() && errors && (
                          <div className="alert alert-danger">
                            Preencha o formulário corretamente
                          </div>
                        )}
                        {errorLogin && (
                          <div className="alert alert-danger">{errorLogin}</div>
                        )}
                        <div className="text-right">
                          <button
                            type="submit"
                            disabled={isFetching}
                            className="home__btn-cta btn btn-primary"
                          >
                            {isFetching ? (
                              <span>
                                <i className="fa fa-spin fa-circle-o-notch" />{' '}
                                Entrando...
                              </span>
                            ) : (
                              <span>Entrar</span>
                            )}
                          </button>
                        </div>
                        {/* <div className="float-right">
                          <a
                            title="Não tenho cadastro"
                            className="btn btn-link clearfix text-capitalize"
                            rel="noopener noreferrer"
                            target="_blank"
                            href={constants.LINK_SEMCADASTRO}
                          >
                            Não tenho cadastro
                          </a>
                          {constants.RECOVERY_PASSWORD_ENABLE && (
                            <button
                              type="button"
                              disabled={isFetching}
                              className="btn btn-link clearfix"
                              onClick={toggleRecoveryCredentials}
                            >
                              Esqueci minha senha
                            </button>
                          )}
                        </div> */}
                      </div>
                    )}
                  </Validator>
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
